import React, { useState, useEffect, useRef } from "react"
import { Space, Row, Col, Form, Input, Button, message } from "antd"
import { FacebookFilled } from "@ant-design/icons"
import axios from "axios"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { FormattedMessage, useIntl, Link } from "gatsby-plugin-intl"

const { Item } = Form

function Footer() {
  const [form] = Form.useForm()
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState({})
  const isFirstRun = useRef(true)

  const success = () => {
    message.success(
      intl.formatMessage({
        id: "message.success",
      })
    )
  }
  const error = () => {
    message.error(
      intl.formatMessage({
        id: "message.error",
      })
    )
  }

  const onFinish = values => {
    setLoading(true)
    axios({
      method: "post",
      url: "https://demo.ioffice.ai/api/v1/leads",
      data: JSON.stringify({ ...values, message: "Đăng ký dùng thử Rava" }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(res => {
        setPayload(res.data)
        setLoading(false)
        window.gtag("Gửi form đăng ký dùng thử thành công", "click")
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }
    if (payload.id) {
      success()
      form.resetFields()
    } else {
      error()
    }
  }, [payload, form])

  return (
    <footer id="Footer" className="footer-wrapper">
      <div className="footer">
        <div className="main">
          <Row>
            <Col md={12}>
              <div className="logo">
                <img alt="img" src="/ioffice_logo_white.svg" width={141} />
              </div>
              {/* <Space style={{ marginBottom: 24 }}>
                <img alt="img" src="/cvs-logo.svg" width={220} />
              </Space> */}
              <Space size={16} direction="vertical">
                <Space direction="vertical">
                  <span className="label">
                    <FormattedMessage id="footer.address1" />
                  </span>
                  <span
                    style={{ display: "block", maxWidth: 451, width: "100%" }}
                  >
                    <FormattedMessage id="footer.address1.content" />
                  </span>
                </Space>
                <Space direction="vertical">
                  <span className="label">
                    <FormattedMessage id="footer.address2" />
                  </span>
                  <span
                    style={{ display: "block", maxWidth: 451, width: "100%" }}
                  >
                    <FormattedMessage id="footer.address2.content" />
                  </span>
                </Space>
                <Space direction="vertical">
                  <span className="label">Email</span>
                  <span>contact@rava.vn</span>
                </Space>
                <Space direction="vertical">
                  <span className="label">Hotline</span>
                  <span>0982.925.220</span>
                </Space>
              </Space>
            </Col>
            <Col
              md={12}
              style={{
                width: "100%",
                maxWidth: 456,
                marginLeft: "auto",
              }}
            >
              <div style={{ fontSize: 24, textAlign: "center", marginTop: 40 }}>
                <FormattedMessage id="footer.form.title" />
              </div>
              <p
                style={{
                  color: "rgba(255, 255, 255, 0.64)",
                  textAlign: "center",
                  margin: "12px 0 28px",
                }}
              >
                <FormattedMessage id="footer.form.desc" />
              </p>
              <Form form={form} onFinish={onFinish}>
                <Item
                  name="name"
                  style={{ marginBottom: 16 }}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: "footer.form.name.message",
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: "footer.form.name.placeholder",
                    })}
                    className="input"
                  />
                </Item>
                <Item
                  name="email"
                  style={{ marginBottom: 16 }}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: "footer.form.email.message",
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: "footer.form.email.placeholder",
                    })}
                    className="input"
                  />
                </Item>
                <Item
                  name="company"
                  style={{ marginBottom: 16 }}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: "footer.form.company.message",
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: "footer.form.company.placeholder",
                    })}
                    className="input"
                  />
                </Item>
                <Item
                  name="address"
                  style={{ marginBottom: 16 }}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: "footer.form.address.message",
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: "footer.form.address.placeholder",
                    })}
                    className="input"
                  />
                </Item>
                <Button
                  block
                  htmlType="submit"
                  shape="round"
                  style={{ height: 40, marginTop: 28 }}
                  loading={loading}
                >
                  <FormattedMessage id="footer.form.button" />
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
        {/* <div className="copyright-wrapper">
          <div></div>
          <Space className="social" size={16}>
            <OutboundLink
              href="https://www.facebook.com/iOfficeAI"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookFilled
                style={{ fontSize: 18, color: "rgba(255, 255, 255, 0.64)" }}
              />
            </OutboundLink>
            <Link to="/terms" style={{ color: "rgba(255, 255, 255, 0.64)" }}>
              <FormattedMessage id="footer.terms_of_use" />
            </Link>
            <Link to="/privacy" style={{ color: "rgba(255, 255, 255, 0.64)" }}>
              <FormattedMessage id="footer.privacy_policy" />
            </Link>
          </Space>
        </div> */}
      </div>
    </footer>
  )
}

export default Footer
