import React from "react"
import { Button } from "antd"
import { FormattedMessage } from "gatsby-plugin-intl"

export const Nav40DataSource = {
  wrapper: { className: "header3 home-page-wrapper" },
  page: { className: "home-page" },
  logo: {
    className: "header3-logo",
    children: "/ioffice_logo.svg",
  },
  Menu: {
    className: "header3-menu",
    children: [
      // {
      //   name: "item6",
      //   className: "header3-item",
      //   href: "https://dashboard.ioffice.ai/",
      //   target: "_blank",
      //   title: (
      //     <Button style={{ fontWeight: 500, height: 40 }}>
      //       <FormattedMessage id="sign_in" />
      //     </Button>
      //   ),
      // },
    ],
  },
  mobileMenu: { className: "header3-mobile-menu" },
}
export const Banner07DataSource = {
  wrapper: { className: "home-page-wrapper banner1" },
  page: { className: "home-page banner1-page" },
  childWrapper: {
    className: "banner1-title-wrapper",
    children: [
      {
        name: "title",
        children: <FormattedMessage id="banner.title" />,
        className: "banner1-title",
      },
      {
        name: "content",
        className: "banner1-content",
        children: (
          <p>
            <FormattedMessage id="banner.description" />
          </p>
        ),
      },
      {
        name: "button",
        className: "banner1-button-wrapper",
        href: "#Footer",
        onClick: () => {
          // trackCustomEvent({
          //   category: "Đăng ký dùng thử",
          //   action: "Click",
          // })
          window.gtag("Đăng ký dùng thử", "click")
        },
        // target: "_blank",
        // rel: "noopener noreferrer",
        children: {
          className: "banner1-button",
          type: "primary",
          children: <FormattedMessage id="banner.button.trial" />,
        },
      },
      {
        name: "note",
        className: "banner1-note",
        children: (
          <p>
            <FormattedMessage id="banner.note1" />
            <span>
              <FormattedMessage id="banner.note2" />
            </span>
          </p>
        ),
      },
    ],
  },
  image: {
    className: "banner1-image",
    children: "/banner1.png",
  },
}

export const Content08DataSource = {
  wrapper: { className: "home-page-wrapper content8-wrapper" },
  page: { className: "home-page content8" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <p style={{ fontWeight: 700, fontSize: 40, lineHeight: "52px" }}>
            <FormattedMessage id="content8.title" />
          </p>
        ),
      },
    ],
  },
  childWrapper: {
    className: "content8-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content8-block",
        md: 8,
        xs: 24,
        children: {
          className: "content8-block-item",
          img: <img src="/app0.svg" alt="img" />,
          title: <FormattedMessage id="content8.children0.title" />,
          content: (
            <>
              <FormattedMessage id="content8.children0.desc1" />
              <br />
              <br />
              <FormattedMessage id="content8.children0.desc2" />
              <br />
              <br />
              <FormattedMessage id="content8.children0.desc3" />
            </>
          ),
        },
      },
      {
        name: "block1",
        className: "content8-block",
        md: 8,
        xs: 24,
        children: {
          className: "content8-block-item",
          img: <img src="/app1.svg" alt="img" />,
          title: <FormattedMessage id="content8.children1.title" />,
          content: (
            <>
              <FormattedMessage id="content8.children1.desc1" />
              <br />
              <br />
              <FormattedMessage id="content8.children1.desc2" />
            </>
          ),
        },
      },
      {
        name: "block2",
        className: "content8-block",
        md: 8,
        xs: 24,
        children: {
          className: "content8-block-item",
          img: <img src="/app2.svg" alt="img" />,
          title: <FormattedMessage id="content8.children2.title" />,
          content: (
            <>
              <FormattedMessage id="content8.children2.desc1" />
              <br />
              <br />
              <FormattedMessage id="content8.children2.desc2" />
              <br />
              <br />
              <FormattedMessage id="content8.children2.desc3" />
            </>
          ),
        },
      },
    ],
  },
}
export const Content01DataSource = {
  wrapper: { className: "home-page-wrapper content1-wrapper" },
  page: { className: "home-page content1" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p style={{ fontSize: 24, fontWeight: 700 }}>
              <FormattedMessage id="content1.title1" />
            </p>
            <p style={{ fontWeight: 700, fontSize: 40, lineHeight: "52px" }}>
              <FormattedMessage id="content1.title2" />
            </p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: "content1-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content1-block",
        md: 6,
        xs: 24,
        children: {
          className: "content1-block-item",
          img: <img src="/step1.png" alt="img" />,
          title: <FormattedMessage id="content1.children0.title" />,
          content: [
            <FormattedMessage id="content1.children0.desc1" />,
            <FormattedMessage id="content1.children0.desc2" />,
          ],
        },
      },
      {
        name: "block1",
        className: "content1-block",
        md: 6,
        xs: 24,
        children: {
          className: "content1-block-item",
          img: <img src="/step2.png" alt="img" />,
          title: <FormattedMessage id="content1.children1.title" />,
          content: [
            <FormattedMessage id="content1.children1.desc1" />,
            <FormattedMessage id="content1.children1.desc2" />,
          ],
        },
      },
      {
        name: "block2",
        className: "content1-block",
        md: 6,
        xs: 24,
        children: {
          className: "content1-block-item",
          img: <img src="/step3.png" alt="img" />,
          title: <FormattedMessage id="content1.children2.title" />,
          content: [<FormattedMessage id="content1.children2.desc" />],
        },
      },
      {
        name: "block3",
        className: "content1-block",
        md: 6,
        xs: 24,
        children: {
          className: "content1-block-item",
          img: <img src="/step4.png" alt="img" />,
          title: <FormattedMessage id="content1.children3.title" />,
          content: [],
        },
      },
    ],
  },
}
export const Content02DataSource = {
  wrapper: { className: "home-page-wrapper content2-wrapper" },
  OverPack: { className: "home-page content2" },
  page: { className: "home-page content2" },
  imgWrapper: { className: "content2-img", md: 12, xs: 24, order: 1 },
  img: {
    children: "/content2.png",
  },
  textWrapper: { className: "content2-text", md: 12, xs: 24, order: 2 },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p style={{ fontSize: 24, fontWeight: 700 }}>
              <FormattedMessage id="content2.title1" />
            </p>
            <p style={{ fontWeight: 700, fontSize: 40, lineHeight: "52px" }}>
              <FormattedMessage id="content2.title2" />
            </p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: "content2-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content2-block",
        children: {
          className: "content2-block-item",
          img: <img src="/content2-1.svg" width={40} alt="img" />,
          title: <FormattedMessage id="content2.children0.title" />,
          content: [<FormattedMessage id="content2.children0.desc" />],
        },
      },
      {
        name: "block1",
        className: "content2-block",
        children: {
          className: "content2-block-item",
          img: <img src="/content2-2.svg" width={40} alt="img" />,
          title: <FormattedMessage id="content2.children1.title" />,
          content: [<FormattedMessage id="content2.children1.desc" />],
        },
      },
      {
        name: "block2",
        className: "content2-block",
        children: {
          className: "content2-block-item",
          img: <img src="/content2-3.svg" width={40} alt="img" />,
          title: <FormattedMessage id="content2.children2.title" />,
          content: [<FormattedMessage id="content2.children2.desc" />],
        },
      },
    ],
  },
}
export const Content03DataSource = {
  wrapper: { className: "home-page-wrapper content3-wrapper" },
  OverPack: { className: "home-page content3" },
  page: { className: "home-page content3" },
  imgWrapper: { className: "content3-img", md: 12, xs: 24, order: 1 },
  img: {
    children: "/content4.png",
  },
  textWrapper: { className: "content3-text", md: 12, xs: 24, order: 2 },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            {/* <p style={{ fontSize: 24, fontWeight: 700 }}>CHUYỂN ĐỔI SỐ</p> */}
            <p style={{ fontWeight: 700, fontSize: 40, lineHeight: "52px" }}>
              <FormattedMessage id="content3.title" />
            </p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: "content3-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content3-block",
        children: {
          className: "content3-block-item",
          img: <img src="/content3-1.svg" width={40} alt="img" />,
          title: <FormattedMessage id="content3.children0.title" />,
          content: [<FormattedMessage id="content3.children0.desc" />],
        },
      },
      {
        name: "block1",
        className: "content3-block",
        children: {
          className: "content3-block-item",
          img: <img src="/content3-2.svg" width={40} alt="img" />,
          title: <FormattedMessage id="content3.children1.title" />,
          content: [<FormattedMessage id="content3.children1.desc" />],
        },
      },
      {
        name: "block2",
        className: "content3-block",
        children: {
          className: "content3-block-item",
          img: <img src="/content3-3.svg" width={40} alt="img" />,
          title: <FormattedMessage id="content3.children2.title" />,
          content: [<FormattedMessage id="content3.children2.desc" />],
        },
      },
    ],
  },
}
export const Content04DataSource = {
  wrapper: { className: "home-page-wrapper content4-wrapper" },
  OverPack: { className: "home-page content4" },
  page: { className: "home-page content4" },
  imgWrapper: { className: "content4-img", md: 12, xs: 24, order: 1 },
  img: {
    children: "/content4.png",
  },
  textWrapper: { className: "content4-text", md: 12, xs: 24, order: 2 },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p style={{ fontWeight: 700, fontSize: 40, lineHeight: "52px" }}>
              <FormattedMessage id="content4.title" />
            </p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: "content4-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content4-block",
        children: {
          className: "content4-block-item",
          img: <img src="/content4-1.svg" width={16} alt="img" />,
          title: <FormattedMessage id="content4.children0.title" />,
          content: "",
        },
      },
      {
        name: "block1",
        className: "content4-block",
        children: {
          className: "content4-block-item",
          img: <img src="/content4-1.svg" width={16} alt="img" />,
          title: <FormattedMessage id="content4.children1.title" />,
          content: "",
        },
      },
      {
        name: "block2",
        className: "content4-block",
        children: {
          className: "content4-block-item",
          img: <img src="/content4-1.svg" width={16} alt="img" />,
          title: <FormattedMessage id="content4.children2.title" />,
          content: "",
        },
      },
      {
        name: "block3",
        className: "content4-block",
        children: {
          className: "content4-block-item",
          img: <img src="/content4-1.svg" width={16} alt="img" />,
          title: <FormattedMessage id="content4.children3.title" />,
          content: "",
        },
      },
    ],
  },
}
export const Content05DataSource = {
  wrapper: { className: "home-page-wrapper content5-wrapper" },
  OverPack: { className: "home-page content5" },
  page: { className: "home-page content5" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p style={{ fontWeight: 700, fontSize: 40, lineHeight: "52px" }}>
              <FormattedMessage id="content5.title" />
            </p>
          </span>
        ),
      },
    ],
  },
  childWrapper1: {
    className: "content5-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content5-block",
        children: {
          itemWrapper: { className: "content5-block-item", md: 15, xs: 24 },
          img: <img src="/content5-1.png" alt="img" />,
        },
      },
      {
        name: "block1",
        className: "content5-block",
        children: {
          itemWrapper: { className: "content5-block-item", md: 9, xs: 24 },
          img: <img src="/content5-2.png" alt="img" />,
        },
      },
    ],
  },
  childWrapper2: {
    className: "content5-block-wrapper",
    children: [
      {
        name: "block2",
        className: "content5-block",
        children: {
          itemWrapper: { className: "content5-block-item", md: 8, xs: 24 },
          img: <img src="/content5-3.png" alt="img" />,
        },
      },
      {
        name: "block3",
        className: "content5-block",
        children: {
          itemWrapper: { className: "content5-block-item", md: 8, xs: 24 },
          img: <img src="/content5-4.png" alt="img" />,
        },
      },
      {
        name: "block3",
        className: "content5-block",
        children: {
          itemWrapper: { className: "content5-block-item", md: 8, xs: 24 },
          img: <img src="/content5-5.png" alt="img" />,
        },
      },
    ],
  },
}
